<script setup lang="ts">
</script>
<template>
 <router-view></router-view>
</template>

<style>
body {
  overflow-x: hidden;
}
</style>
